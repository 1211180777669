import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Snackbar} from "@material-ui/core";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import CurrentlyPlaying from "../Components/CurrentlyPlaying/CurrentlyPlaying";
import QueueOptions from "../Components/QueueOptions/QueueOptions";
import SongSuggestion from "../Components/SongSuggestion/SongSuggestion";
import sortAndReturnNumerically, {
  sortAndReturnAlphabetically,
} from "../Helpers/sort";
import FlipMove from 'react-flip-move';
import "./LiveQueue.css";

const socket = io.connect(`${process.env.REACT_APP_BACKEND_BASE_URL}`);

function LiveQueue() {
  const [currentSong, setCurrentSong] = useState({});
  const [songsInQueue, setSongsInQueue] = useState([]);
  const [songProgress, setSongProgress] = useState(0);
  const [songDuration, setSongDuration] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortedByRank, setSortedByRank] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const navigate = useNavigate();

  const defaultAlbumArtworkURL = "https://static.vecteezy.com/system/resources/thumbnails/002/249/673/small/music-note-icon-song-melody-tune-flat-symbol-free-vector.jpg";

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") return;
    setToastOpen(false);
  };

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let event_name = params.get("event_name");

  const sortQueue = useCallback(
    (queue) => {
      return sortedByRank
        ? sortAndReturnNumerically(queue)
        : sortAndReturnAlphabetically(queue);
    },
    [sortedByRank]
  );

  const loadPlaylistSongs = useCallback(() => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/event_songs?event_name=${event_name}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        const song_list = data.songs.map((song) => ({
          name: song.name,
          artist: song.artist,
          id: song.id,
          votes: song.votes,
          spotify_id: song.spotify_id,
          image_url: song.image_url
        }));
        setSongsInQueue(song_list); // We'll handle sorting with useMemo
      })
      .catch((error) => {
        setIsErrorState(true);
      });
  }, [event_name]);

  const onVote = (index, voteChange)  => {
    socket.emit("vote", {
      song: songsInQueue[index].id,
      change: voteChange,
    });
    const updatedQueue = [...songsInQueue];
    updatedQueue[index].votes += voteChange;
    setSongsInQueue(sortQueue(updatedQueue));
  };

  const deleteSuggestion = (index) => {
    const updatedQueue = [...songsInQueue];
    updatedQueue.splice(index, 1);
    setSongsInQueue(sortQueue(updatedQueue));
  };

  const toggleSortType = () => {
    setSortedByRank(!sortedByRank);
  };

  const sortedSongsInQueue = useMemo(() => {
    return sortQueue(songsInQueue);
  }, [songsInQueue, sortQueue]);

  const addSongToQueue = (name, artist, song) => {
    return;
  }

  const updateCurrentSong = useCallback(() => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ event_name: event_name }),
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/currently_playing`,
      requestOptions
    )
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setSongProgress(data.progress_ms / 1000);
        setSongDuration(data.duration_ms / 1000);
        if (data.id === currentSong.id) return;
        else {
          setCurrentSong({
            name: data.name,
            artist: data.artist,
            albumWorkURL: data.image_url,
            id: data.id,
            spotifyId: data.spotify_id
          });
          localStorage.setItem(data.id.toString(), "0");
        }
      });
    // Note: You don't need to include `setCurrentSong`, `setSongProgress`, or `setSongDuration` in the dependency array because React's state setters are stable.
  }, [event_name, currentSong.id]);

  useEffect(() => {
    if(isAdmin) {
      setToastOpen(true);
    }
  }, [isAdmin]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/status`,
          {
            method: "GET",
            credentials: "include", // Important to include cookies with the request
          }
        );
        const data = await response.json();
        setIsAdmin(data.eventName === event_name);
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };
    checkAuthStatus();
  }, [navigate]);

  useEffect(() => {
    updateCurrentSong();
    const intervalId = setInterval(updateCurrentSong, 5000);
    return () => clearInterval(intervalId);
  }, [updateCurrentSong]);

  useEffect(() => {
    loadPlaylistSongs();

    socket.on("send_vote", (newRating) => {
      console.log("Received vote from socket", newRating);
      setSongsInQueue((prevQueue) => {
        const updatedQueue = prevQueue.map((song) => {
          if (song.id === newRating.songId) {
            return {
              ...song,
              votes: newRating.rating,
            };
          }
          return song;
        });
        return sortQueue(updatedQueue);
      });
    });

    return () => {
      socket.off("send_vote");
    };
  }, [loadPlaylistSongs, sortQueue]);

  useEffect(() => {
    if(!currentSong.name) {
      setCurrentSong({
        name: "Spotify playback paused.",
        artist: "Make sure Spotify is playing.",
        albumWorkURL: defaultAlbumArtworkURL,
      });
    }
  }, [currentSong.name]);

  return (
    <div className="event_page__container">
      <div className="navigation__container">
        <h3 className="navigation__home" onClick={() => navigate("/home")}>
          TrackTap.
        </h3>
        <button
            className="navigation__button"
            onClick={() => navigate("/create_event")}
          >
            Create Event
        </button>
      </div>
      <div className="queue__container">
        <div className="queue__header">
          <CurrentlyPlaying
            name={currentSong.name}
            artist={currentSong.artist}
            spotifyId={currentSong.spotifyId}
            albumWorkURL={currentSong.albumWorkURL}
            eventName={event_name}
            songProgress={songProgress}
            songDuration={songDuration}
          />
        </div>

        <QueueOptions
          sortedByRank={sortedByRank}
          toggleSortType={toggleSortType}
          addSongToQueue={addSongToQueue}
          eventName={event_name}
          songList={songsInQueue}
          isAdmin={isAdmin}
        />

        {!isErrorState ? (
          <div className="song__queue">
            <FlipMove>
            {sortedSongsInQueue.map((song, index) => (
              <SongSuggestion
                key={`${song.id}`}
                ref={React.createRef()}
                name={song.name}
                id={song.id}
                spotifyId={song.spotify_id}
                artist={song.artist}
                votes={song.votes}
                imageUrl={song.image_url}
                index={index}
                onVote={onVote}
                eventName={event_name}
                deleteSuggestion={deleteSuggestion}
              />
            ))}
            </FlipMove>
          </div>
        ) : (
          <div className="error_message">
            <h3>Oops! This event code doesn't seem to be valid.</h3>
            <button onClick={() => navigate("/home")}>
              Go Back
            </button>
          </div>
        )}
        <Snackbar
          open={toastOpen}
          autoHideDuration={8000}
          onClose={handleToastClose}
        >
          <Alert
            onClose={handleToastClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thanks for using Tracktap! Please clear your queue to begin, Tracktap can only add songs to the back of your queue. Please let songs finish playing, skipping a song prevents Tracktap from queueing the highest voted songs.
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default LiveQueue;