import React from "react";
import "./SongSuggestion.css";
import VotingButtons from "./VotingButtons/VotingButtons";
import SpotifyIcon from "../SVG/SpotifyIcon";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';


const SongSuggestion = React.forwardRef((props, ref) => {
  return (
    <div className="song__card" ref={ref}>
        <div className="song__card-1">
          <img
              className="song__albumwork"
              src={props.imageUrl}
              alt={props.name}
          />
          <div className="song__info">
            <h4 className="song__info--name">{props.name}</h4>
            <h5 className="song__info--artist">{props.artist}</h5>
          </div>
          <a className="song__info--link" href={"https://open.spotify.com/track/" + props.spotifyId.slice(14)}>
            <SpotifyIcon />
            <ArrowOutwardIcon sx={{ color: 'white'}} />
          </a>
        </div>

      <div className="song__interactions">
        <div className="votes__counter--container">
          <h3 className="song__votes song__votes--number">{props.votes}</h3>
          <span className="song__votes song__votes--text">votes</span>
        </div>
        {/*<button className="song__card__buy">
            <p>Play Next</p>
        </button>*/}

        <VotingButtons
          onVote={props.onVote}
          songId={props.id}
          index={props.index}
          eventName={props.eventName}
        />
      </div>
    </div>
  );
});

export default SongSuggestion;
