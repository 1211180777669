import { Box, Button, IconButton, Modal, Typography } from "@material-ui/core";
import { Close, Sort } from "@material-ui/icons";
import React, { useState } from "react";

import "./QueueOptions.css";
import {useNavigate} from "react-router-dom";

function QueueOptions(props) {

  const [addSongsOpen, setAddSongsOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const handleOpen = () => setAddSongsOpen(true);
  const handleClose = () => setAddSongsOpen(false);
  const navigate = useNavigate();
  
  const inputHandler = (e) => {
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  

  const filteredData = props.songList.filter((song) => {
    if (inputText === "") return song;

    return (
      song.name.toLowerCase().includes(inputText) ||
      song.artist.toLowerCase().includes(inputText)
    );
  });

  return (
    <div className="QueueOptions">
      <Button
        color="primary"
        startIcon={<Sort />}
        onClick={() => {
          props.toggleSortType();
        }}
      >
        Sort {props.sortedByRank ? "Alphabetically" : "by Rank"}
      </Button>
        {props.isAdmin && (
          <Button
            color="secondary"
            onClick={() => {
              const requestOptions = {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  body: JSON.stringify({ eventName: props.eventName }),
                };
                fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/end_event`, requestOptions).then(r => {
                    if (r.status === 204) {
                        navigate("/home");
                    }
                });
            }}
          >
            End Event
          </Button>
        )}

      <Modal open={addSongsOpen} onClose={handleClose}>
        <Box>
          <Typography
            variant="h5"
            component="h2"
            align="center"
          >
            Song Search
          </Typography>

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>

          <input type="text" placeholder="Song Name" onChange={inputHandler} />

          <Box>
            {filteredData.map((song) => (
              <Box
                key={song.name}
                onClick={() => {
                  props.addSongToQueue(song.name, song.artist,song);
                  handleClose();
                }}
              >
                <Typography variant="h5" component="h3">
                  {song.name}
                </Typography>
                <Typography variant="h6" component="h4">
                  {song.artist}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>

    </div>
  );
}

export default QueueOptions;
