import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./Landing.css";
import demoUi from '../Assets/demo-ui.png';


function LandingPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  function spotifyAuth() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/spotify`, requestOptions)
      .then((res) => {
        if (!res.ok && res.status === 400) {
          return res.json().then((data) => {
            setError(data.error);
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
          (data.spotifyAuthorized && data.authenticated)
            ? navigate("/create_event")
            : window.location.replace(data.spotifyAuthLink);
      });
  }

  function logout() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sign_out`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.reload();
      });
  }

    const Footer = () => {
      const currentYear = new Date().getFullYear();

      return (
        <footer style={{ textAlign: "center", padding: "10px", fontSize: "14px"}}>
          <p>© {currentYear} Robert Sheiman. All rights reserved.</p>
          <p style={{ color: "#888" }}>
            This product is currently in beta. To gain access, please send the email address associated
            with your Spotify account to <a style={{ color: "#888" }} href="mailto:sheiman.robert.18@gmail.com">sheiman.robert.18@gmail.com</a>
          </p>
        </footer>
      );
    };

  return (
    <React.Fragment>
    <div className="landing__viewport">
    <div className="landing__container">
      <h2 className="title">TrackTap.</h2>

        <div className="landing__header--container">
            <div className="landing__header--left">
                <h1>Pick the Tunes, Set the Mood. Together.</h1>
                <p>✅ Select a playlist from Spotify.</p>
                <p>🗳️ Vote on songs to craft a queue, together.</p>
                <p>🎛️ Controls the music at your hangout or gathering.</p>
            </div>
            <div className="landing__header--right">
                <div className="phone-frame">
                  <div className="phone-top"></div> {/* Simulates phone speaker area */}
                  <img src={demoUi} alt="landing" className="ui-screenshot" />
                </div>
            </div>
        </div>


      <form
        className="queueForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {error && <p>Please enter an event code.</p>}
        <input type="text" id="event_code_input" placeholder="Event Code" />
        <button
          className="landingPage__button"
          type="submit"
          onClick={() => {
              const eventName = document.getElementById("event_code_input").value;
              if (eventName.trim() === '') {
                setError(true);
              } else {
                setError(false);
                navigate("/queue?event_name=" + eventName);
              }
            }
          }
        >
          Join Event
        </button>
          <hr />
        <button
            className="landingPage__button"
            onClick={() => spotifyAuth()}
          >
            Create Event
        </button>
      </form>
      <br /><br />
        <Footer />
    </div>
    </div>
    </React.Fragment>
  );
}

export default LandingPage;
