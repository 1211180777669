import React from "react";
import "./CurrentlyPlaying.css";
import ShowQRCodeButton from "./ShowQRCodeButton/ShowQRCodeButton";
import SpotifyIcon from "../SVG/SpotifyIcon";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function CurrentlyPlaying(props) {
  const progressPercentage = (props.songProgress / props.songDuration) * 100;

  return (
    <div className="currentlyPlaying__wrapper">
      <div className="currentlyPlaying__container">
        <div className="currentlyPlaying__albumworkName--container">
          <div className="currentlyPlaying__albumArtworkAndLogo">
            <img
              className="currentlyPlaying__albumwork"
              src={props.albumWorkURL}
              alt={props.name}
            />
          </div>

          <div className="currentlyPlaying__songInfo">
            <h2 className="currentlyPlaying__songInfo--name">{props.name}</h2>
            <h3 className="currentlyPlaying__songInfo--artist">{props.artist}</h3>
            {props.spotifyId && (
                <a className="song__info--link" href={"https://open.spotify.com/track/" + props.spotifyId.slice(14)}>
                  <SpotifyIcon />
                  <ArrowOutwardIcon sx={{ color: 'white' }} />
                </a>
            )}
          </div>
        </div>

        <div className="currentlyPlaying__lockTimer">
          <ShowQRCodeButton eventName={props.eventName}/>
        </div>
      </div>

      <div className="currentlyPlaying__progressBar">
        <div
          className="currentlyPlaying__progressBar--fill"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default CurrentlyPlaying;
