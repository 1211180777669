import React from "react";
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./SessionCode.css"
import ShareButton from "../Components/ShareButton";

function SessionCode(props) {
  const [size] = useState(400);
  const [qrCode, setQrCode] = useState("");
  const [loading, setLoading] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let event_name = params.get('event_name');
  let eventURL = `${process.env.REACT_APP_FRONTEND_BASE_URL}/queue?event_name=` + event_name;

  useEffect(() => {
      setLoading(true);
      setQrCode(`http://api.qrserver.com/v1/create-qr-code/?data=${eventURL}&size=${size}x${size}`);
      setLoading(false);
  }, [event_name, size]);

  const navigate = useNavigate();
  return (
    <div className='session-code-container'>
      <h1>Share this queue with a friend 💓</h1>
      <span>You're sharing an event named {event_name}</span>

      <div className="output-box">
          <div className="headerButtons">
              <button onClick={() => navigate("/queue?event_name="+event_name)}>Back</button>
              <ShareButton
                url={eventURL}
                title="My TrackTap Event"
                text="Vote on my music queue on TrackTap!"
              />
          </div>
          {loading ? <CircularProgress sx={{ color: 'white'}} className="loading" /> : <img src={qrCode} alt="" className="fadeInGrow" />}
      </div>
    </div>
  );
}

export default SessionCode;
