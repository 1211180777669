import { IconButton } from "@material-ui/core";
import { ThumbUp, ThumbDown } from "@material-ui/icons";
import { useState, useEffect } from "react";

export default function VotingButtons({ songId, eventName, index, onVote }) {
  const [vote, setVote] = useState(0); // 0: none, 1: upvote, -1: downvote

  useEffect(() => {
    const savedVote = localStorage.getItem(`${songId}-${eventName}`);
    if (savedVote) {
      setVote(Number(savedVote));
    }
  }, [songId, eventName]);

  const handleVote = (newVote) => {
    const isRemovingVote = newVote === vote;
    const nextVote = isRemovingVote ? 0 : newVote;
    const prevVote = vote;

    // Update local state
    setVote(nextVote);
    localStorage.setItem(`${songId}-${eventName}`, nextVote.toString());

    // Calculate vote change for socket
    let voteChange;
    if (nextVote === 1) {
      // Upvoting
      voteChange = prevVote === -1 ? 2 : 1; // If was downvoted, change is +2, else +1
    } else if (nextVote === -1) {
      // Downvoting
      voteChange = prevVote === 1 ? -2 : -1; // If was upvoted, change is -2, else -1
    } else {
      // Removing vote
      voteChange = prevVote === 1 ? -1 : 1; // If removing upvote, change is -1, if removing downvote, change is +1
    }

    onVote(index, voteChange);
  };

  return (
    <div style={{ display: 'flex', gap: '0' }}>
      <IconButton
        onClick={() => handleVote(1)}
        style={{
          color: vote === 1 ? 'gray' : '#4BB543',
          padding: '2px'
        }}
      >
        <ThumbUp
          style={{
            transform: 'scale(0.7)',
            padding: '0',
            fill: vote === 1 ? 'green' : 'white'
          }}
        />
      </IconButton>
      <IconButton
        onClick={() => handleVote(-1)}
        style={{
          color: vote === -1 ? 'gray' : '#eb5534',
          padding: '2px'
        }}
      >
        <ThumbDown
          style={{
            transform: 'scale(0.7)',
            fill: vote === -1 ? 'red' : 'white'
          }}
        />
      </IconButton>
    </div>
  );
}