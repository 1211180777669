import React, {useEffect, useState} from "react";
import ToggleButton from '@mui/material/ToggleButton';
import CheckIcon from "@mui/icons-material/Check";
import "./PlaylistCard.css";
import SpotifyIcon from "./../SVG/SpotifyIcon";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function PlaylistCard(props){

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if(!props.selected){
            setSelected(false);
        }
    }, [props.selected])

    return (
        <div className="playlistCard__container">
            <img
                className="playlistCard__image"
                src={props.playlistArtURL}
                alt={props.name}
            />
            <div className="playlistCard__title">
                <h2>
                    {props.name}
                </h2>
                <p>From your Spotify</p>
            </div>
            <a className="song__info--link" href={"https://open.spotify.com/track/" + props.playlistId.slice(14)}>
                <SpotifyIcon />
                <ArrowOutwardIcon sx={{ color: 'white'}} />
            </a>
            <ToggleButton
                value="check"
                color="standard"
                selected={selected}
                onChange={() => {
                    if(selected){
                        setSelected(false);
                        props.onSelect("");
                    }else{
                        props.onSelect(props.playlistId);
                        setSelected(true);
                    }
                }}
            >
                <CheckIcon style={{ color: 'white' }} />
            </ToggleButton>
        </div>
    );

}

export default PlaylistCard;