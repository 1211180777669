import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";

const ShareButton = ({ url, title, text }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title || "Check this out!",
          text: text || "I found something interesting:",
          url: url || window.location.href,
        });
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <button
      onClick={handleShare}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}
    >
      <IosShareIcon />
      Share
    </button>
  );
};

export default ShareButton;